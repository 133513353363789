import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@mui/material";
import Box from "@mui/material/Box";
import {useDispatch, useSelector} from "../store";
import {ALLOY_VOICE, ECHO_VOICE, FABLE_VOICE, ONYX_VOICE, NOVA_VOICE, SHIMMER_VOICE, selectVoice, setVoice} from "../store/slices/app";

export default function VoiceChoiceRadioGroup() {

    const dispacth = useDispatch();
    const voice = useSelector(selectVoice);

    const onChange = e => {

        dispacth(setVoice(e.target.value));
    };

    console.log({voice})

    return (
        <FormControl>
            <FormLabel id="voice-choice-radio-group-label" focused={false}>Овоз</FormLabel>
            <RadioGroup
                aria-labelledby="voice-choice-radio-group-label"
                defaultValue="alloy"
                name="voice-choice-radio-group"
                value={voice}
                onChange={onChange}
            >
                <FormControlLabel value={ALLOY_VOICE} control={<Radio />} label="Alloy" />
                <FormControlLabel value={ECHO_VOICE} control={<Radio />} label="Echo" />
                <FormControlLabel value={FABLE_VOICE} control={<Radio />} label="Fable" />
                <FormControlLabel value={ONYX_VOICE} control={<Radio />} label="Onyx" />
                <FormControlLabel value={NOVA_VOICE} control={<Radio />} label="Nova" />
                <FormControlLabel value={SHIMMER_VOICE} control={<Radio />} label="Shimmer" />
            </RadioGroup>
        </FormControl>
    );
}

/*
v=spf1 include:spf.unisender.com include:sendersrv.com include:_spf.yandex.net ~all
v=spf1 include:spf.unisender.com include:sendersrv.com include=_spf.yandex.net ~all
 */