export const BASE_PATH = '/';

export const THEME_MODE_LIGHT = 'light';
export const THEME_MODE_DARK = 'dark';

const config = {
    fontFamily: `'Inter Variable', sans-serif`,
    // fontFamily: `'Inter', sans-serif`,
    themeMode: THEME_MODE_DARK, // light, dark
    locale: 'ru', // 'en' - English, 'ru' - Russian, 'uz' - Uzbekistan
};

export default config;