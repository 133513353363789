import Box from "@mui/material/Box";
import {LANGUAGE_UZ} from "../../store/slices/app";
import {Paper, Typography, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import AudioPlayButton from "../../ui-components/AudioPlayButton";

export default function Message({children, audio, language, translation}) {
    // console.log({audio})
    const theme = useTheme();
    const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));

    const isOwner = language === LANGUAGE_UZ;

    return (
        <Box
            sx={{
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: isOwner ? 'flex-end' : 'flex-start',
            }}
        >
            <Box sx={{
                maxWidth: matchUpSm ? '80%' : '100%'}}>
                <Paper
                    elevation={2}
                    sx={{
                        py: 1.5,
                        px: 1,
                        mb: 2,
                        borderRadius: 3,
                        ...(isOwner ? {borderBottomRightRadius: 0} : {borderBottomLeftRadius: 0}),
                        background: isOwner ? theme.palette.background.paper3 : theme.palette.background.paper4
                    }}
                >
                    {
                        translation &&
                        <Box pb={1}><Typography variant="caption">{translation}</Typography></Box>
                    }
                    <Typography variant="h4" color={theme.palette.text.primary}><AudioPlayButton url={audio} sx={{position: 'relative', top: '-2px'}}/> {children}</Typography>
                </Paper>
            </Box>
        </Box>
    );
}