import {createSlice} from '@reduxjs/toolkit';


const initialState = {
    isFetching: null,
    isFetched: null,
    error: null,
    phone: null,
    email: null,
    userType: null,
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        setIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        setIsFetched(state, action) {
            state.isFetched = action.payload;
        },
    }
});

export default slice.reducer;

export const {hasError, setIsFetching, setIsFetched, } = slice.actions;

export const selectError = ({user: {error}}) => error;
export const selectIsFetching = ({user: {isFetching}}) => isFetching;
export const selectIsFetched = ({user: {isFetched}}) => isFetched;