import {useEffect, useRef, useState} from "react";
import {IconButton} from "@mui/material";
import {IconVolume} from '@tabler/icons-react';
import { IconPlayerStopFilled } from '@tabler/icons-react';
import useSound from 'use-sound';

export default function AudioPlayButton({url, sx = {}}) {

    const [playing, setPlaying] = useState(false);

    const onLoad = () => {
        console.log('onLoad');
    };

    const onPlay = () => {
        setPlaying(true);
    };

    const onEnd = () => {
        setPlaying(false);
    };

    const onStop = () => {
        setPlaying(false);
    };

    const [play, {stop, sound}] = useSound(
        url,
        {
            interrupt: true,
            onload: onLoad,
            onplay: onPlay,
            onend: onEnd,
            onstop: onStop ,
        }
    );
    // const [playUI] = useSound('../assets/sound/mixkit-select-click-1109.wav');



    const onClick = e => {

        e.preventDefault();

        if (playing) {

            stop();

        } else {

            play();
        }

    };
    // console.log({sound: sound})
    return (
        <IconButton onClick={onClick} size="small" edge="start" color="primary" sx={{...sx}}>
            {
                playing
                    ? <IconPlayerStopFilled size={16} />
                    : <IconVolume size={16} />
            }
        </IconButton>
    );
}