import Box from "@mui/material/Box";
import {Divider, Grid, List, Paper, Snackbar, Typography, useTheme} from "@mui/material";
import PerfectScrollbar from 'react-perfect-scrollbar';
import Controls from "./Controls";
import Container from "@mui/material/Container";
import {useSelector} from "../../store";
import {selectMessages} from "../../store/slices/app";
import Message from "./Message";
import {useRef} from "react";
import useElementSize from "../../utils/useElementSize";

export default function ConversationTranslator() {

    const theme = useTheme();
    const messages = useSelector(selectMessages);
    const messageContainer = useRef(null);
    const {width: messageContainerWidth, height: messageContainerHeight} = useElementSize(messageContainer);

    function getMessageKey({text, audio, index}) {

        return `${text}-${audio || ''}-${index}`;
    }

    return (
        <Box
            sx={{
                flexFlow: 'column nowrap',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                height: '100%',
            }}
        >
            <Box
                sx={{
                    flexGrow: 1,
                    height: 'calc(100% - 142px)',
                    overflow: 'auto'
                }}
                ref={messageContainer}
            >
                <Box>
                    <Container maxWidth="sm" sx={{py: 3}}>

                        {
                            messages.map(({text, audio, language, translation}, index) => (
                                <Message key={getMessageKey({text, audio, index})} language={language} audio={audio} translation={translation}>{text}</Message>
                            ))
                        }
                    </Container>
                </Box>
            </Box>
            <Box>
                <Paper elevation={0} square sx={{background: theme.palette.background.default}}>
                    <Divider/>
                    <Container maxWidth="sm"><Controls/></Container>
                </Paper>
            </Box>
        </Box>
    );
}