import {IconMicrophoneFilled} from "@tabler/icons-react";
import {CircularProgress, Fab, Typography, useTheme} from "@mui/material";
import Box from "@mui/material/Box";
import useRequestAnimationFrameCountdown from "../../hooks/useRequestAnimationFrameCountdown";
import {useEffect, useState} from "react";

export default function VoiceRecordButton({text, onStart = () => {}, onStop = () => {}, language, isRecording, disabled = true, loading = false}) {

    const TIME = 5;

    const theme = useTheme();

    const [playing, setPlaying] = useState(false);

    const {time, start: startCountdown, stop: stopCountdown, reset: resetCountdown} = useRequestAnimationFrameCountdown(TIME);

    const progress = (TIME - time) / (TIME / 100);

    const start = () => {

        onStart(language)
            .then(() => {

                resetCountdown();
                startCountdown();
                setPlaying(true);
            });
    };

    const stop = () => {

        onStop(language)
            .then(() => {

                stopCountdown();
                resetCountdown();
                setPlaying(false);
            });
    };

    const onClick = e => {

        e.preventDefault();

        if (playing) {

            stop();

        } else {

            start();
        }

    };

    useEffect(() => {

        if (time === 0) {

            stop();
        }

    }, [time]);

    return (
        <Box>
            <Box sx={{textAlign: 'center', pb: 1, maxWidth: '74px', mx: 'auto', fontSize: '0.875rem'}}>
                <Typography variant="caption"><Box sx={{lineHeight: 1.2}}>{text}</Box></Typography>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                <Box sx={{position: 'relative'}}>
                    <CircularProgress
                        size={46}
                        thickness={2}
                        value={progress < 0 ? 0 : progress}
                        // color={theme.palette.primary[200]}
                        variant={!disabled && loading ? 'indeterminate' : 'determinate'}
                        sx={{
                            // 'circle': {stroke: (!disabled && loading) ? theme.palette.primary.main : theme.palette.error.main },
                            'circle': {stroke: (!disabled && loading) ? theme.palette.primary.main : theme.palette.grey[500] },
                            position: 'absolute',
                            top: -3,
                            left: -3,
                            zIndex: 5,
                            transition: 'none',
                            "& .MuiCircularProgress-circle": {
                                transition: 'none',
                            },
                        }}
                    />

                    <Fab
                        color={playing ? 'error' : 'default'}
                        aria-label="add"
                        size="small"
                        sx={{position: 'relative', zIndex: 10,}}
                        onClick={onClick}
                        disabled={disabled || loading}
                    >
                        {
                            playing
                                ? time.toFixed(1)
                                : <IconMicrophoneFilled/>
                        }

                    </Fab>
                </Box>
            </Box>
        </Box>
    );
}