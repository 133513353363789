import {useEffect, useRef, useState} from "react";
import {Button, Grid} from "@mui/material";
import Box from "@mui/material/Box";
import RecordRTC, { invokeSaveAsDialog } from 'recordrtc';
import VoiceRecordButton from "./VoiceRecordButton";
import {useDispatch, useSelector} from "../../store";
import {
    addMessage, LANGUAGE_RU, LANGUAGE_UZ,
    selectLastAudioMessageUrl, selectRecording, selectVoice,
    setLastAudioMessageUrl, setRecording
} from "../../store/slices/app";
import {addEventsListener} from "../../utils/addEventsListener";
import {removeEventsListener} from "../../utils/removeEventsListener";
import translateSpeech from "../../api/translateSpeech";
import Field from "./Field";
import translateText from "../../api/translateText";


export default function Controls() {

    const dispatch = useDispatch();
    const format = 'webm';
    const [isRecording, setIsRecording] = useState(false);
    const [language, setLanguage] = useState(null);
    const [loading, setLoading] = useState(false);
    const recorderRef = useRef(null);
    const streamRef = useRef(null);
    const voice = useSelector(selectVoice);

    const startRecording = async (language) => {

        streamRef.current = await navigator.mediaDevices.getUserMedia({ audio: true });

        recorderRef.current = new RecordRTC(streamRef.current, {
            type: 'audio',
            mimeType: `audio/${format}`,
            bitsPerSecond: 128000,
        });

        setIsRecording(true);

        recorderRef.current.startRecording();

        setLanguage(language);
    };

    const stopRecording = async (language) => {

        setIsRecording(false);

        setLoading(true);

        const recorder = recorderRef.current;

        await recorder.stopRecording(async () => {

            const blob = recorder.getBlob();

            try {

                const response = await translateSpeech({blob, language, voice});

                if (response.hasOwnProperty('text') && response.text) {

                    console.log({'response.text': response});

                    dispatch(addMessage(response));
                }

            } catch (error) {

                alert(`${error.name}: ${error.message}`);
            }

            recorderRef.current = null;

            setLoading(false);

            setLanguage(null);
        });

        streamRef.current.getTracks().forEach(function (track = {stop: () => {}}) {
            track.stop();
        });
    };

    const onTextSend = async (text, language) => {

        setLoading(true);

        setLanguage(language);

        try {

            const response = await translateText({text, language, voice});

            if (response.hasOwnProperty('text') && response.text) {

                console.log({'response.text': response});

                dispatch(addMessage(response));
            }

            setLoading(false);

            setLanguage(null);

        } catch (error) {

            alert(`${error.name}: ${error.message}`);
        }
    };

    return (
        <Box py={2}>
            <Box sx={{maxWidth: '400px', mx: 'auto'}}>
                <Grid container spacing={2} justifyContent="space-around" flexWrap="nowrap" alignItems="flex-end">
                    <Grid item sx={{position: 'relative', bottom: '-1px'}}>
                        <VoiceRecordButton
                            text="Рус тилида гапиринг"
                            onStart={startRecording}
                            onStop={stopRecording}
                            language={LANGUAGE_RU}
                            isRecording={isRecording}
                            disabled={language !== null && language !== LANGUAGE_RU}
                            loading={loading}
                        />
                    </Grid>
                    <Grid item xs>
                        <Field
                            disabled={isRecording || language !== null && language !== LANGUAGE_UZ}
                            loading={loading}
                            onSend={onTextSend}
                            language={LANGUAGE_UZ}
                        />
                        {/*<VoiceRecordButton*/}
                        {/*    text="Ўзбек тили"*/}
                        {/*    onStart={startRecording}*/}
                        {/*    onStop={stopRecording}*/}
                        {/*    language={LANGUAGE_UZ}*/}
                        {/*    isRecording={isRecording}*/}
                        {/*    disabled={language !== null && language !== LANGUAGE_UZ}*/}
                        {/*    loading={loading}*/}
                        {/*/>*/}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}