import {createSlice, current} from "@reduxjs/toolkit";
import {store} from '../index';


export const LANGUAGE_RU = 'ru';
export const LANGUAGE_UZ = 'uz';

export const ALLOY_VOICE = 'alloy';
export const ECHO_VOICE = 'echo';
export const FABLE_VOICE = 'fable';
export const ONYX_VOICE = 'onyx';
export const NOVA_VOICE = 'nova';
export const SHIMMER_VOICE = 'shimmer';

const initialState = {
    isFetching: null,
    isFetched: null,
    error: null,
    voice: ALLOY_VOICE,
    lastAudioMessageUrl: null,
    recording: false,
    generating: false,
    messages: [],
    lastClosePollDate: null,
};

const slice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setIsFetching(state, action) {
            state.isFetching = action.payload;
        },
        setIsFetched(state, action) {
            state.isFetched = action.payload;
        },
        hasError(state, action) {
            state.error = action.payload;
        },
        setVoice(state, action) {
            state.voice = action.payload;
        },
        setLastAudioMessageUrl(state, action) {
            state.lastAudioMessageUrl = action.payload;
        },
        setRecording(state, action) {
            state.recording = action.payload;
        },
        setGenerating(state, action) {
            state.generating = action.payload;
        },
        setLastClosePollDate(state, action) {
            state.lastClosePollDate = action.payload;
        },
        addMessage(state, action) {
            // console.log({
            //     'store.getState()': store.getState(),
            //     'store.getState().app': store.getState().app,
            //     'store.getState().app.messages': store.getState().app.messages,
            //     'action.payload': action.payload,
            //     current,
            // });

            const currentState = current(state);

            state.messages = [
                ...currentState.messages,
                {
                    text: action.payload.text,
                    audio: action.payload.audio,
                    language: action.payload.language,
                    voice: action.payload.voice,
                    translation: action.payload.translation || null,
                }
            ];
        },
        clearMessages(state, action) {
            state.messages = [];
        },
    }
});

export default slice.reducer;

export const {hasError, setIsFetching, setIsFetched, setVoice, setLastAudioMessageUrl, setRecording, addMessage, clearMessages, setLastClosePollDate, } = slice.actions;

export const selectError = ({app: {error}}) => error;
export const selectIsFetching = ({app: {isFetching}}) => isFetching;
export const selectIsFetched = ({app: {isFetched}}) => isFetched;
export const selectVoice = ({app: {voice}}) => voice;
export const selectLastAudioMessageUrl = ({app: {lastAudioMessageUrl}}) => lastAudioMessageUrl;
export const selectRecording = ({app: {recording}}) => recording;
export const selectMessages = ({app: {messages}}) => messages;
export const selectLastClosePollDate = ({app: {lastClosePollDate}}) => lastClosePollDate;