import {createContext} from 'react';
import defaultConfig, {THEME_MODE_DARK, THEME_MODE_LIGHT} from '../config';
import useLocalStorage from 'hooks/useLocalStorage';
import useMediaQuery from '@mui/material/useMediaQuery';

const initialState = {
    ...defaultConfig,
    onChangeLocale: () => {},
    onChangeThemeMode: () => {},
};

const ConfigContext = createContext(initialState);

// eslint-disable-next-line
function ConfigProvider({children}) {

    const systemThemeMode = useMediaQuery('(prefers-color-scheme: light)') ? THEME_MODE_LIGHT : THEME_MODE_DARK;

    const [config, setConfig] = useLocalStorage('theme-config', {
        fontFamily: initialState.fontFamily,
        themeMode: systemThemeMode,
        locale: initialState.locale,
    });

    const onChangeLocale = (locale) => {
        setConfig({...config, locale});
    };

    const onChangeThemeMode = (themeMode) => {

        setConfig({...config, themeMode});
    };

    return (
        <ConfigContext.Provider value={{...config, onChangeLocale, onChangeThemeMode,}}>
            {children}
        </ConfigContext.Provider>
    );
}

export {ConfigProvider, ConfigContext};