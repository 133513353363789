import { useState, useRef, useCallback, useEffect } from 'react';

function useRequestAnimationFrameCountdown(initialTime) {

    const [time, setTime] = useState(initialTime);
    const requestRef = useRef(null);
    const previousTimeRef = useRef(null);

    const animate = useCallback((time) => {

        if (previousTimeRef.current != null) {

            const deltaTime = time - previousTimeRef.current

            setTime(prevTime => Math.max(prevTime - deltaTime * 0.001, 0)); // время в секундах, не меньше 0
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animate);
    }, []);

    const start = useCallback(() => {

        if (requestRef.current === null) {

            previousTimeRef.current = performance.now();
            requestRef.current = requestAnimationFrame(animate);
        }
    }, [animate]);

    const stop = useCallback(() => {

        if (requestRef.current !== null) {

            cancelAnimationFrame(requestRef.current);
            requestRef.current = null;
            previousTimeRef.current = null;
        }
    }, []);

    useEffect(() => {

        return () => {

            if (requestRef.current !== null) {

                cancelAnimationFrame(requestRef.current);
            }
        };
    }, []);

    useEffect(() => {

        if (time === 0) {

            stop();
        }
    }, [time, stop]);

    return { time, start, stop, reset: () => setTime(initialTime) };
}

export default useRequestAnimationFrameCountdown;