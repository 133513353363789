import {useState} from "react";
import {Button, IconButton, Snackbar, Typography, useTheme} from "@mui/material";
import Grow from '@mui/material/Grow';
import { IconX } from '@tabler/icons-react';
import Alert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import { IconListNumbers } from '@tabler/icons-react';
import { IconShare3 } from '@tabler/icons-react';
import {setLastClosePollDate} from "../../store/slices/app";
import {useDispatch, useSelector} from "../../store";

export default function PollSnackbar() {

    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);

    const close = () => {

        setOpen(false);

        dispatch(setLastClosePollDate(Date.now()));
    }

    const handleClose = (event, reason) => {

        if (reason === 'clickaway') {
            return;
        }

        close();
    };

    return (
        <Snackbar
            open={open}
            anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            onClose={handleClose}
            TransitionComponent={Grow}
            sx={{zIndex: 25}}
        >
            <Alert
                onClose={handleClose}
                icon={false}
                severity="primary"
                variant="filled"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <IconX fontSize="small" />
                    </IconButton>
                }
            >
                <Box pb={1.5}>
                    <Typography variant="h5" color={theme.palette.text.white}>Изга маҳсулотни яхшилашга ёрдам беринг, чунки у мутлақо бепул.</Typography>
                </Box>
                <Box >
                    <Button
                        variant="outlined"
                        color="dark"
                        component="a"
                        href="https://forms.gle/i8zdYCx31b2dt2AU6"
                        target="_blank"
                        size="small"
                        // startIcon={<IconShare3/>}
                        endIcon={<IconShare3 size={18}/>}
                        onClick={close}
                    >
                        Сўровномадан ўтинг
                    </Button>
                </Box>
            </Alert>
        </Snackbar>
    );
}
