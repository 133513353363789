import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Button, FormLabel} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import { IconTrash } from '@tabler/icons-react';
import { IconCheck } from '@tabler/icons-react';
import {useDispatch} from "../store";
import {clearMessages, setLastClosePollDate} from "../store/slices/app";

export default function ClearMessagesButton() {

    const dispatch = useDispatch();
    const [clicked, setClicked] = useState(false);

    const onClick = e => {

        e.preventDefault();

        setClicked(true);

        dispatch(clearMessages());

        dispatch(setLastClosePollDate(null));
    };

    useEffect(() => {

        setTimeout(() => {

            setClicked(false);

        }, 3000);

    }, [clicked]);

    return (
        <Box>
            <Box py={1}>
                <FormLabel id="theme-mode-radio-group-label" focused={false}>Маълумотларни тозалаш</FormLabel>
            </Box>
            <Box sx={{maxWidth: '200px'}}>
                <LoadingButton onClick={onClick} variant="outlined" endIcon={clicked ? <IconCheck size="18px"/> : <IconTrash size="18px"/>}>Тозалаш</LoadingButton>
            </Box>
        </Box>
    );
}