import {useState, useEffect, useCallback} from 'react';

const useElementSize = (elementRef) => {

    const [elementSize, setElementSize] = useState({
        width: 0,
        height: 0,
    });

    const listener = useCallback(() => elementRef?.current && setElementSize({
            width: elementRef.current.offsetWidth,
            height: elementRef.current.offsetHeight,
        }), [elementRef]
    );

    const isMedia = elementRef.current instanceof HTMLMediaElement;

    useEffect(() => {

        listener();

        window.addEventListener('load', listener);
        window.addEventListener('resize', listener);

        return () => {

            window.removeEventListener('load', listener);
            window.removeEventListener('resize', listener);
        };
    }, []);

    useEffect(() => {

        listener();

        if (isMedia) {

            elementRef?.current.addEventListener('canplaythrough', listener);
        }

        return () => {

            if (elementRef.current) {

                elementRef.current.removeEventListener('canplaythrough', listener);
            }
        };
    }, [isMedia]);

    return elementSize;
}

export default useElementSize