import api from './axios';

export default function postRequest(url, data = {}) {

    return new Promise((resolve, reject) => {
        console.log('data instanceof FormData', data instanceof FormData)

        const config = {};

        if (data instanceof FormData) {

            config.headers = {
                'Content-Type': 'multipart/form-data',
                'content-type': 'multipart/form-data',
                'Accept': 'multipart/form-data',
                'accept': 'multipart/form-data',
            };

            config.transformRequest = formData => formData;
        }

        console.log({config})

        api.post(url, data, config)
            .then(response => {

                resolve(response.data);
            })
            .catch(error => {

                reject(error);
            })
            .then(() => {

                reject();
            });
    });
}
