// material-ui
import {createTheme} from '@mui/material/styles';

// assets
// import defaultColor from 'assets/scss/_themes-vars.module.scss';
import colors from 'assets/scss/_theme.module.scss';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //

const Palette = (themeMode) => {

    return createTheme({
        palette: {
            mode: themeMode,
            common: {
                black: colors.darkPaper,
                white: colors.paper,
            },
            invertText: {
                main: themeMode === 'dark' ? colors.grey900 : colors.paper,
                contrastText: themeMode === 'dark' ? colors.paper : colors.grey900,
            },
            primary: {
                light: themeMode === 'dark' ? colors.darkPrimaryLight : colors.primaryLight,
                main: themeMode === 'dark' ? colors.darkPrimaryMain : colors.primaryMain,
                dark: themeMode === 'dark' ? colors.darkPrimaryDark : colors.primaryDark,
                200: themeMode === 'dark' ? colors.darkPrimary200 : colors.primary200,
                400: themeMode === 'dark' ? colors.darkPrimary400 : colors.primary400,
                600: themeMode === 'dark' ? colors.darkPrimary600 : colors.primary600,
                800: themeMode === 'dark' ? colors.darkPrimary800 : colors.primary800,
            },
            secondary: {
                light: themeMode === 'dark' ? colors.darkSecondaryLight : colors.secondaryLight,
                main: themeMode === 'dark' ? colors.darkSecondaryMain : colors.secondaryMain,
                dark: themeMode === 'dark' ? colors.darkSecondaryDark : colors.secondaryDark,
                200: themeMode === 'dark' ? colors.darkSecondary200 : colors.secondary200,
                800: themeMode === 'dark' ? colors.darkSecondary800 : colors.secondary800
            },
            error: {
                light: colors.errorLight,
                main: colors.errorMain,
                dark: colors.errorDark
            },
            orange: {
                light: colors.orangeLight,
                main: colors.orangeMain,
                dark: colors.orangeDark
            },
            warning: {
                light: colors.warningLight,
                main: colors.warningMain,
                dark: colors.warningDark
            },
            success: {
                light: colors.successLight,
                200: colors.success200,
                main: colors.successMain,
                dark: colors.successDark
            },
            grey: {
                50: colors.grey50,
                100: colors.grey100,
                200: colors.grey200,
                300: colors.grey300,
                400: colors.grey400,
                500: themeMode === 'dark' ? colors.darkTextSecondary : colors.grey500,
                600: themeMode === 'dark' ? colors.darkTextTitle : colors.grey900,
                700: themeMode === 'dark' ? colors.darkTextPrimary : colors.grey700,
                900: themeMode === 'dark' ? colors.darkTextPrimary : colors.grey900
            },
            dark: {
                light: colors.darkTextPrimary,
                main: colors.darkLevel1,
                dark: colors.darkLevel2,
                800: colors.darkBackground,
                900: colors.darkPaper
            },
            text: {
                primary: themeMode === 'dark' ? colors.darkTextPrimary : colors.grey700,
                secondary: themeMode === 'dark' ? colors.darkTextSecondary : colors.grey500,
                dark: themeMode === 'dark' ? colors.darkTextPrimary : colors.grey900,
                hint: colors.grey100,
                white: colors.paper,
            },
            divider: themeMode === 'dark' ? colors.grey900 : colors.grey200,
            background: {
                paper: themeMode === 'dark' ? colors.darkLevel2 : colors.paper,
                paper2: themeMode === 'dark' ? colors.darkLevel1 : colors.paper,
                paper3: themeMode === 'dark' ? colors.darkLevel3 : colors.paper,
                paper4: themeMode === 'dark' ? colors.darkLevel4 : colors.paper,
                paper5: themeMode === 'dark' ? colors.grey900 : colors.paper,
                default: themeMode === 'dark' ? colors.darkPaper : colors.paper
            }
        }
    });
};

export default Palette;
