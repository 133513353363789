import {useMemo} from 'react';
import {CssBaseline, StyledEngineProvider} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {ruRU} from '@mui/material/locale';
import useConfig from 'hooks/useConfig';
import Palette from './palette';
import Typography from './typography';
import componentStyleOverrides from "./compStyleOverride";

export default function ThemeCustomization({children}) {

    const {fontFamily, themeMode} = useConfig();
    const theme = useMemo(() => Palette(themeMode), [themeMode]);
    const themeTypography = useMemo(() => Typography(theme, fontFamily), [theme, fontFamily]);
    const themeOptions = useMemo(
        () => ({
            direction: 'ltr',
            palette: theme.palette,
            mixins: {
                toolbar: {
                    minHeight: '48px',
                    padding: '16px',
                    '@media (min-width: 600px)': {
                        minHeight: '48px'
                    }
                }
            },
            typography: themeTypography
        }),
        [theme, themeTypography]
    );

    const themes = createTheme(themeOptions, ruRU);

    // themes.components = useMemo(
    //     () => componentStyleOverrides(themes),
    //     [themes]
    // );

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline/>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
// A custom theme for this app
// const index = createTheme({
//     palette: {
//         primary: {
//             main: '#556cd6',
//         },
//         secondary: {
//             main: '#19857b',
//         },
//         error: {
//             main: red.A400,
//         },
//     },
// });

// export default index;
