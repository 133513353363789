import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import useConfig from 'hooks/useConfig';
import {
    BottomNavigation, BottomNavigationAction, Divider,
    Drawer,
    Fab,
    Grid,
    IconButton, Paper, Snackbar,
    SpeedDial,
    SpeedDialIcon,
    SwipeableDrawer,
    ToggleButton,
    ToggleButtonGroup, Typography,
    useTheme
} from "@mui/material";
import {IconSettings} from '@tabler/icons-react';
import {IconSettingsFilled} from '@tabler/icons-react';
import {IconMenu} from '@tabler/icons-react';
import {IconMoonFilled} from '@tabler/icons-react';
import {IconSunFilled} from '@tabler/icons-react';
import {IconMicrophoneFilled} from '@tabler/icons-react';
import {THEME_MODE_DARK, THEME_MODE_LIGHT} from "./config";
import {useState} from "react";
import {IconArrowLeft} from '@tabler/icons-react';
import {IconX} from '@tabler/icons-react';
import useMediaQuery from "@mui/material/useMediaQuery";
import ThemeModeToggleButton from "./ui-components/ThemeModeToggleButton";
import VoiceChoiceRadioGroup from "./ui-components/VoiceChoiceRadioGroup";
import ConversationTranslator from "./features/ConversationTranslator";
import ClearMessagesButton from "./ui-components/ClearMessagesButton";
import {IconMessages} from '@tabler/icons-react';
import {IconDeviceFloppy} from '@tabler/icons-react';
import PollSnackbar from "./features/PollSnackbar";
import {useSelector} from "./store";
import {selectLastClosePollDate} from "./store/slices/app";

export default function App() {

    const NAVIGATION_TRANSLATOR = 'NAVIGATION_TRANSLATOR';
    const NAVIGATION_ARCHIVE = 'NAVIGATION_ARCHIVE';

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    const lastClosePollDate = useSelector(selectLastClosePollDate);
    console.log({lastClosePollDate})
    const showPollSnackbar = lastClosePollDate === null;

    const [open, setOpen] = useState(false);
    const [navigationValue, setNavigationValue] = useState();

    const onNavigation = (event, newValue) => {

        setNavigationValue(newValue);
    };

    const toggleDrawer = (newOpen) => () => {

        setOpen(newOpen);
    };

    return (
        <Box
            component="main"
            sx={{
                display: 'flex',
                flexFlow: 'column nowrap',
                height: '100%',
            }}
        >
            <SwipeableDrawer
                open={open}
                anchor="right"
                onOpen={toggleDrawer(true)}
                onClose={toggleDrawer(false)}
                disableSwipeToOpen
                allowSwipeInChildren={false}
                PaperProps={{
                    sx: {
                        backgroundColor: theme.palette.background.paper5,
                        width: {
                            xs: '100%',
                            md: '320px'
                        },
                        pb: 5
                    },
                }}
                ModalProps={{
                    keepMounted: false,
                }}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box p={1}>
                            <Grid container spacing={3} justifyContent={{xs: 'flex-start', md: 'flex-end'}}>
                                <Grid item>
                                    <IconButton onClick={toggleDrawer(false)}>
                                        {
                                            matchUpMd
                                                ? <IconX/>
                                                : <IconArrowLeft/>
                                        }
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={2}>
                            <VoiceChoiceRadioGroup/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={2}>
                            <ThemeModeToggleButton/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box px={2}>
                            <ClearMessagesButton/>
                        </Box>
                    </Grid>
                </Grid>
            </SwipeableDrawer>
            <Box
                sx={{
                    position: 'relative',
                    overflow: 'auto',
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    transform: !matchUpMd && open ? 'translate(-60px, 0)' : 'translate(0, 0)',
                    transition: 'transform ease .3s',
                    height: '100%',
                }}
            >
                {
                    showPollSnackbar &&
                    <PollSnackbar/>
                }

                <Box sx={{width: '100%', position: 'relative', zIndex: 20}}>
                    <Paper elevation={0} square sx={{background: theme.palette.background.default}}>
                        <Box p={1}>
                            <Grid container justifyContent="flex-end" spacing={3}>
                                <Grid item>
                                    <IconButton onClick={toggleDrawer(true)}>
                                        <IconMenu/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    </Paper>
                    <Divider/>
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        flexGrow: 1,
                        height: 'calc(100% - 64px)',
                        position: 'relative',
                        zIndex: 15,
                    }}
                >
                    <ConversationTranslator/>
                </Box>

                {/*<Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={3}>*/}
                {/*    <Box item sx={{width: '100%'}}>*/}

                {/*        <BottomNavigation value={navigationValue} onChange={onNavigation}>*/}
                {/*            <BottomNavigationAction*/}
                {/*                label="Таржимон"*/}
                {/*                value={NAVIGATION_TRANSLATOR}*/}
                {/*                icon={<IconMessages/>}*/}
                {/*            />*/}
                {/*            <BottomNavigationAction*/}
                {/*                label="Сақланган"*/}
                {/*                value={NAVIGATION_ARCHIVE}*/}
                {/*                icon={<IconDeviceFloppy/>}*/}
                {/*            />*/}
                {/*        </BottomNavigation>*/}
                {/*    </Box>*/}
                {/*</Paper>*/}
                {/*<Container maxWidth="sm" sx={{position: 'relative', height: '100vh', width: '100%'}}>*/}
                {/*    <Box sx={{my: 4}}>*/}
                {/*        <IconButton onClick={onThemeModeClick}>*/}
                {/*            {*/}
                {/*                themeMode === THEME_MODE_LIGHT*/}
                {/*                    ? <IconMoonFilled/>*/}
                {/*                    : <IconSunFilled/>*/}
                {/*            }*/}
                {/*        </IconButton>*/}
                {/*        <Fab sx={{position: 'absolute', bottom: 16, right: 16,}} color="secondary"><IconMicrophoneFilled/></Fab>*/}
                {/*        /!*<Typography variant="h4" component="h1" sx={{ mb: 2 }}>*!/*/}
                {/*        /!*  Material UI Create React App example*!/*/}
                {/*        /!*</Typography>*!/*/}
                {/*        /!*<ProTip />*!/*/}
                {/*        /!*<Copyright />*!/*/}
                {/*    </Box>*/}
                {/*</Container>*/}

            </Box>
        </Box>
    );
}

/*
1. Добавляем текстовое поле с подсказкой в нем «Пиши по узбекски». Когда пользователь пишет по узбекски и отправляет, то появляется сообщение с русским переводом и оригинальным текстом (как у тебя сейчас). Русский перевод основной, оригинал наверху. При нажатии на прослушивание воспроизводится перевод на русском.
2. Значок голоса переносим в право около текстового поля с подсказкой «Говори по русски». После того, как речь записана и отправлена, она превращается в сообщение, где узбекский перевод главный, а русский второстепенный. При нажатии на прослушивание воспроизводится перевод на узбекском.
3. Ограничения: запись голоса 5 сек. Остальные ограничения потом подумаем.
 */