import {CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField} from "@mui/material";
import { IconSend } from '@tabler/icons-react';
import {useEffect, useState} from "react";

export default function Field({disabled, loading, language, onSend}) {

    const MAX_LETTER_LENGTH = 60;

    const [isEmpty, setIsEmpty] = useState(true);
    const [error, setError] = useState(null);
    const [value, setValue] = useState('');

    const _onChange = e => {

        const value = e.target.value;

        setError(value.length > MAX_LETTER_LENGTH ? `Рухсат етилган белгилар сони: ${MAX_LETTER_LENGTH}` : null);

        setValue(value);
    };

    const onSendClick = e => {

        e.preventDefault();

        onSend(value, language)
            .then(() => {

                setValue('');
            });
    };

    useEffect(() => {

        setIsEmpty(!value);

    }, [value]);

    return (
        <TextField
            fullWidth
            variant="outlined"
            multiline
            maxRows={4}
            label={`Ўзбек тилида ёзинг${value.length > 0 ? ' ('+ value.length +'/'+ MAX_LETTER_LENGTH +')' : ''}`}
            onChange={_onChange}
            helperText={error}
            error={!!error}
            disabled={loading || disabled}
            value={value}
            size="small"
            InputProps={{
                sx: {
                    borderRadius: 3,
                },
                endAdornment: (
                    <InputAdornment
                        position="end"
                        sx={{
                            alignSelf: 'flex-end',
                            maxHeight: '1.5em',
                        }}
                    >
                        <IconButton
                            edge="end"
                            size="small"
                            disabled={isEmpty || loading || disabled}
                            onClick={onSendClick}
                        >
                            {
                                loading && !disabled
                                    ? <CircularProgress size={24}/>
                                    : <IconSend />
                            }

                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}
