import postRequest from "../utils/postRequest";

const URL = '/public/translate-speech';

export default async function translateSpeech({blob, language, voice}) {

    const formData = new FormData();

    formData.append('audio', blob);

    if (language) {

        formData.append('language', language);
    }

    if (voice) {

        formData.append('voice', voice);
    }

    return postRequest(URL, formData);
};