import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import appReducer from './slices/app';

const reducer = combineReducers({
    user: userReducer,
    app: persistReducer(
        {
            key: 'app',
            storage,
            keyPrefix: 'app-',
            messages: [],
            blacklist: ['isFetching', 'isFetched', 'error', 'lastAudioMessageUrl', 'recording', 'generating']
        },
        appReducer
    ),
});

export default reducer;