import {FormLabel, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {THEME_MODE_DARK, THEME_MODE_LIGHT} from "../config";
import {IconMoonFilled, IconSunFilled} from "@tabler/icons-react";
import useConfig from "../hooks/useConfig";
import Box from "@mui/material/Box";

export default function ThemeModeToggleButton() {

    const {themeMode, onChangeThemeMode} = useConfig();
    console.log({themeMode})
    const onChange = (e, value) => {

        if (value) {

            onChangeThemeMode(value);
        }
    };

    return (
        <Box>
            <Box py={1}>
                <FormLabel id="theme-mode-radio-group-label" focused={false}>Мавзу</FormLabel>
            </Box>
            <Box sx={{maxWidth: '200px'}}>
                <ToggleButtonGroup color="primary" exclusive fullWidth size="small" value={themeMode} onChange={onChange}>
                    <ToggleButton value={THEME_MODE_DARK}>
                        <IconMoonFilled/>
                    </ToggleButton>
                    <ToggleButton value={THEME_MODE_LIGHT}>
                        <IconSunFilled/>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
        </Box>
    );
}
