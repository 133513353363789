import {configureStore} from '@reduxjs/toolkit';
import {useDispatch as useAppDispatch, useSelector as useAppSelector} from 'react-redux';
import {persistStore, createTransform} from 'redux-persist';
import rootReducer from './reducer';

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false, immutableCheck: false})
});

const persistor = persistStore(store);

const {dispatch} = store;

const useDispatch = () => useAppDispatch();

const useSelector = useAppSelector;

export {store, persistor, dispatch, useSelector, useDispatch};