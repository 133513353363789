// import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import {CookiesProvider} from 'react-cookie';
import {ConfigProvider} from 'contexts/ConfigContext';
import {store} from 'store';
import {BASE_PATH} from 'config';
import App from './App';
import ThemeCustomization from "./themes";
import 'assets/scss/common.scss';
import '@fontsource-variable/inter';
import 'assets/scss/style.scss';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
    <Provider store={store}>
        <ConfigProvider>
            <BrowserRouter basename={BASE_PATH}>
                <CookiesProvider>
                    <ThemeCustomization>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline/>
                        <App/>
                    </ThemeCustomization>
                </CookiesProvider>
            </BrowserRouter>
        </ConfigProvider>
    </Provider>
);
